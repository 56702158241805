// onlyoffice 类型
export function handleOnlyofficeType(fileType) {
    let docType = '';
    let fileTypesDoc = [
        'doc', 'docm', 'docx', 'dot', 'dotm', 'dotx', 'epub', 'fodt', 'htm', 'html', 'mht', 'odt', 'ott', 'pdf',
        'rtf', 'txt', 'djvu', 'xps'
    ];
    let fileTypesCsv = [
        'csv', 'fods', 'ods', 'ots', 'xls', 'xlsm', 'xlsx', 'xlt', 'xltm', 'xltx'
    ];
    let fileTypesPPt = [
        'fodp', 'odp', 'otp', 'pot', 'potm', 'potx', 'pps', 'ppsm', 'ppsx', 'ppt', 'pptm', 'pptx'
    ];
    if (fileTypesDoc.includes(fileType)) {
        docType = 'text';
    }
    if (fileTypesCsv.includes(fileType)) {
        docType = 'spreadsheet';
    }
    if (fileTypesPPt.includes(fileType)) {
        docType = 'presentation';
    }
    return docType;
}

//处理文件类型
export function handleFileType(fileType) {
    const office = [
        'doc', 'docm', 'docx', 'dot', 'dotm', 'dotx', 'mht', 'odt', 'ott',
        'rtf', 'txt', 'xps', 'csv', 'fods', 'ods', 'ots', 'xls', 'xlsm', 'xlsx', 'xlt', 'xltm', 'xltx',
        'fodp', 'odp', 'otp', 'pot', 'potm', 'potx', 'pps', 'ppsm', 'ppsx', 'ppt', 'pptm', 'pptx'
    ];
    const image = ['bmp', 'jpg', 'png', 'tif', 'gif', 'webp','jpeg'];
    const pdf = ['pdf'];
    if (office.includes(fileType)) {

        return 'office';
    }
    if (image.includes(fileType)) {
        return 'image';
    }
    if (pdf.includes(fileType)) {
        return 'pdf';
    }
}

export function parseQuery() {
    let queryStr = location.search.replace("?", "");
    let queryList = queryStr.split("&");
    let query = {}
    for (let q of queryList) {
        let [key, value] = q.split("=");
        query[key] = value
    }
    return query;
}

export const marknameStorage = {
    set(v) {
        sessionStorage.setItem("markname", v)
    },
    get() {
        return sessionStorage.getItem("markname")||''
    }
}