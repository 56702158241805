<template>
    <div id="app">
        <template v-if="loading">
            <img class="loading" src="./assets/loading.png"/>
        </template>
        <template v-else-if="error">
            <div class="error-w">
                <img src="./assets/error.png">
                <span>文件暂不支持查看</span>
            </div>
        </template>
        <template v-else>
            <template v-if="fileType==='office'">
                <template v-if="isImm">
                    <imm :data="params"/>
                </template>
                <template v-else>
                    <onlyoffice :data="params"/>
                </template>
            </template>
            <template v-else-if="fileType==='image'">
                <f-image :data="params"/>
            </template>
            <template v-else-if="fileType==='pdf'">
                <f-pdf :data="params"/>
            </template>
        </template>
    </div>
</template>
<script>

    import {handleFileType, marknameStorage, parseQuery} from "./common/utils";
    import Imm from "./components/f-imm";
    import Onlyoffice from "./components/f-onlyoffice";
    import FImage from "./components/f-image";
    import FPdf from "./components/f-pdf";
    import Axios from "axios"

    export default {
        components: {Imm, Onlyoffice, FImage, FPdf},
        data() {
            return {
                isImm: false,//是否imm展示office
                fileType: null,
                params: null, //文件需要的参数
                loading: true,
                error: false //文件查看错误
            }
        },
        methods: {
            init() {
                const query = parseQuery();
                Axios.get(query.url, {params: {fileMark: query.fileMark}}).then(res => {
                    this.loading = false;
                    res = res.data;
                    if ((res.code === 200 || res.status) && res.data.onlineView) {
                        this.fileType = handleFileType(res.data.fileType);
                        this.isImm = res.data.isImm;
                        if (res.data.watermarkContent) {
                            //控制水印
                            marknameStorage.set(res.data.watermarkContent)
                        }
                        switch (this.fileType) {
                            case "office":
                                if (this.isImm) {
                                    res.data.immVisit.refreshUrl = query.refreshUrl;
                                    this.params = res.data.immVisit;
                                } else {
                                    res.data.onlyOfficeVisit.fileType = res.data.fileType;
                                    res.data.onlyOfficeVisit.fileName = res.data.fileName;
                                    res.data.onlyOfficeVisit.isEdit = res.data?.isEdit;
                                    this.params = res.data.onlyOfficeVisit;
                                }
                                break;
                            case "image":
                                this.params = {
                                    title: res.data.fileName,
                                    url: res.data.otherFileViewPath
                                };
                                break;
                            case "pdf":
                                this.params = {
                                    title: res.data.fileName,
                                    url: res.data.otherFileViewPath
                                };
                                break;
                        }
                    } else {
                        this.error = true;
                    }
                })
            }
        },
        created() {
            this.init();
        },
    }
</script>
<style lang="less">
    body {
        margin: 0;
    }

    #app {
        height: 100vh;
        width: 100vw;
        overflow: hidden;

        .loading {
            margin: 0 auto;
            display: block;
            margin-top: 45vh;
            animation: load 1s linear infinite;
            width: 36px;
            height: 36px;
        }

        @keyframes load {
            from {
                transform: rotate(0);
            }
            50% {
                transform: rotate(180deg);
            }
            to {
                transform: rotate(360deg);
            }
        }

        .error-w {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 35vh;
            color: gray;
        }
    }
</style>
