/**
* @Author：duxin
* @Date：2022-01-25
* @desc：
*/

<template>
    <div id="container" style="height: 100%;"></div>
</template>

<script>
    import Axios from "axios"

    export default {
        name: "imm",
        props: {
            data: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },
        data() {
            return {}
        },
        mounted() {
            this.init();
        },
        methods: {
            init() {
                const refreshToken = async () => {
                    //业务处理逻辑，调用服务端封装的refreshToken接口。
                    let tokenRes = await Axios.get(this.data.refreshUrl, {
                        params: {
                            accessToken: this.data.accessToken,
                            refreshToken: this.data.refreshToken
                        }
                    })
                    let tokenInfo = tokenRes.data.data;
                    this.data.accessToken = tokenInfo.accessToken;
                    this.data.refreshToken = tokenInfo.refreshToken;
                    return {
                        token: tokenInfo.accessToken,
                        timeout: 5 * 60 * 1000
                    }
                }
                let instance = aliyun.config({
                    mount: document.querySelector('#container'),
                    url: this.data.webofficeURL,
                    refreshToken
                })
                instance.setToken({
                    token: this.data.accessToken,
                    timeout: 5 * 60 * 1000,
                })

                this.hide(instance);
            },
            async hide(instance) {
                await instance.ready();

                const app = instance.Application;

                const historyRecord = await app.CommandBars('HistoryRecord');
                const historyVersion = await app.CommandBars('HistoryVersion');
                const moreMenus = await app.CommandBars('MoreMenus');

                //控制更多菜单禁用
                historyRecord.Enabled = false;
                historyVersion.Enabled = false;
                moreMenus.Enabled = false;
            }
        }
    }
</script>

<style lang="less" scoped>

</style>