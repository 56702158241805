/**
* @Author：duxin
* @Date：2022-01-25
* @desc：
*/

<template>
    <div class="image-w">
        <h2>{{data.title}}</h2>
        <rh-image class="rh-image" :src="data.url"/>
    </div>
</template>

<script>
    import RhImage from "./image/rh-image";

    export default {
        name: "f-image",
        components: {RhImage},
        props: {
            data: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },
        data() {
            return {}
        }
    }
</script>

<style lang="less" scoped>
    .image-w {
        height: 100%;
        display: flex;
        flex-direction: column;

        h2 {
            text-align: center;
            margin: 5px;
            font-size: 16px;
        }

        .rh-image {
            flex: 1;
        }
    }
</style>