import Vue from "vue";
import App from "./App.vue";
// import router from "./router";
import loading from "./components/loading";

Vue.config.productionTip = false;
Vue.use(loading);
new Vue({
    render: (h) => h(App),
}).$mount("#app");
