/**
* @Author：duxin
* @Date：2022-01-25
* @desc：
*/

<template>
    <iframe width="100%" height="98%" :src="pdfSrc"></iframe>
</template>

<script>
    import {marknameStorage} from "../common/utils";

    export default {
        name: "f-pdf",
        props: {
            data: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },
        data() {
            return {}
        },
        computed: {
            markname() {
                return marknameStorage.get()
            },
            pdfSrc() {
                let _src = `pdfjs2.0/web/viewer.html?file=${this.data.url}`
                if (this.markname) {
                    _src += `&markname=${this.markname}`
                }
                return _src
            }
        }
    }
</script>

<style lang="less" scoped>

</style>