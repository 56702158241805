/**
* @Author：duxin
* @Date：2022-01-25
* @desc：onlyoffice  文件加载
*/

<template>
    <div id="edocOffice"></div>
</template>

<script>
    import {handleOnlyofficeType} from "../common/utils";

    export default {
        name: "onlyoffice",
        props: {
            data: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },
        data() {
            return {}
        },
        mounted() {
            this.init(this.data)
        },
        methods: {
            init(option) {
                if (this.editor) {
                    this.editor.destroyEditor();
                }
                let doctype = handleOnlyofficeType(option.fileType);
                // office配置参数
                let config = {
                    document: {
                        fileType: option.fileType,
                        key: option.key || null,
                        title: option.fileName,
                        permissions: {
                            comment: false,
                            download: false,
                            modifyContentControl: false,
                            modifyFilter: false,
                            print: false,
                            edit: option.isEdit,//是否可以编辑: 只能查看，传false
                        },
                        url: option.fileViewPath
                    },
                    documentType: doctype,
                    editorConfig: {
                        callbackUrl: option.callbackUrl,//"编辑word后保存时回调的地址，这个api需要自己写了，将编辑后的文件通过这个api保存到自己想要的位置
                        lang: "zh",//语言设置
                        customization: {
                            autosave: false,//是否自动保存
                            spellcheck: true,//拼写检查
                            "forcesave": false
                        },
                        "user": {
                            "name": option.userName
                        }
                    },
                    width: "100%",
                    height: "100%",
                };
                this.editor = new DocsAPI.DocEditor("edocOffice", config);
            }
        }
    }
</script>

<style lang="less" scoped>

</style>