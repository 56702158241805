/**
* @Author：duxin
* @Date：2022-01-28
* @desc：
*/

<template>
    <div id="dxImg">
        <div id="imageW">
            <div class="image-w">
                <img id="image" :src="src"/>
                <template v-if="markname">
                    <span class="cover" v-for="i in 100" :key="i" :style="handleP(i)">{{markname}}</span>
                </template>
            </div>
        </div>
        <div class="mask">

        </div>
    </div>
</template>

<script>

    import {marknameStorage} from "../../common/utils";

    export default {
        name: "rh-image",
        props: {
            src: String
        },
        data() {
            this.markCount = 10;
            return {
                ratioThreshold: 0.1,
                minRatio: 0.1,
                maxRatio: 16,
                MOUSEDOWN: "wheel mousewheel DOMMouseScroll"
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            init() {
                this.$W = $(window);
                this.$D = $(document);
                this.$wEle = $("#dxImg");
                this.$imageW = $("#imageW");
                this.$image = $("#image");
                this.$imageMask = $(".mask");

                let image = new Image();
                image.onload = () => {
                    this.imageData = {
                        originalWidth: this.$image.width(),
                        originalHeight: this.$image.height(),
                        width: this.$image.width(),
                        height: this.$image.height()
                    }

                    this.$imageW.css({
                        width: this.$image.width(),
                        height: this.$image.height()
                    })

                    //鼠标滚轮事件
                    this.$wEle.on(this.MOUSEDOWN, this.wheel);
                    //鼠标单击事件
                    this.$imageMask.on("mousedown", this.down);
                    //鼠标抬起事件
                    this.$D.on("mouseup", this.up);
                }
                image.src = this.src;
            },
            wheel(e) {
                let delta = 1;
                if (e.originalEvent.deltaY) {
                    delta = e.originalEvent.deltaY > 0 ? 1 : -1;
                } else if (e.originalEvent.wheelDelta) {
                    delta = -e.originalEvent.wheelDelta / 120;
                } else if (e.originalEvent.detail) {
                    delta = e.originalEvent.detail > 0 ? 1 : -1;
                }
                let ratio = -delta * this.ratioThreshold;
                let pointer = {
                    x: e.originalEvent.clientX - this.$wEle.offset().left + this.$D.scrollLeft(),
                    y: e.originalEvent.clientY - this.$wEle.offset().top + this.$D.scrollTop()
                };
                this.zoom(ratio, pointer, e);
            },
            zoom(ratio, origin) {
                ratio = ratio < 0 ? (1 / (1 - ratio)) : (1 + ratio);
                if (ratio > 0.95 && ratio < 1.05) {
                    ratio = 1;
                }
                ratio = parseInt(this.imageData.width) / this.imageData.originalWidth * ratio;
                ratio = Math.max(ratio, this.minRatio);
                ratio = Math.min(ratio, this.maxRatio);

                let $imageW = this.$imageW;
                let $wEle = this.$wEle;
                let imgData = {
                    w: this.imageData.width,
                    h: this.imageData.height,
                    x: this.imageData.left,
                    y: this.imageData.top
                };
                let stageData = {
                    w: $wEle.width(),
                    h: $wEle.height(),
                    x: $wEle.offset().left,
                    y: $wEle.offset().top
                };
                let newWidth = this.imageData.originalWidth * ratio;
                let newHeight = this.imageData.originalHeight * ratio;
                let newLeft = origin.x - (origin.x - imgData.x) / imgData.w * newWidth;
                let newTop = origin.y - (origin.y - imgData.y) / imgData.h * newHeight;

                let imgNewWidth = !this.isRotated ? newWidth : newHeight;
                let imgNewHeight = !this.isRotated ? newHeight : newWidth;
                //先调整图片大小
                $imageW.css({
                    width: Math.round(newWidth),
                    height: Math.round(newHeight)
                });

                //再调整图片位置
                //获取当前的大小，如果有旋转，会有变化
                let imgCurrWidth = $imageW.width();
                let imgCurrHeight = $imageW.height();
                if (imgNewWidth <= stageData.w) {
                    //缩小了 或者 没变
                    newLeft = (stageData.w - imgCurrWidth) / 2;
                } else {
                    //放大了
                    newLeft = -(imgCurrWidth - stageData.w) / 2;
                }
                if (imgNewHeight <= stageData.h) {
                    newTop = (stageData.h - imgCurrHeight) / 2;
                } else {
                    newTop = -(imgCurrHeight - stageData.h) / 2;
                }

                $imageW.css({
                    left: Math.round(newLeft),
                    top: Math.round(newTop)
                })

                $.extend(this.imageData, {
                    width: newWidth,
                    height: newHeight,
                    left: newLeft,
                    top: newTop
                });
            },
            down(e) {
                let imgWidth = this.$imageW.width();
                let imgHeight = this.$imageW.height();

                this.startX = e.clientX;
                this.startY = e.clientY;
                this.gt = !this.isRotated ? 0 : (imgWidth - imgHeight) / 2;

                this.mLeft = this.$imageW.position().left - this.gt;
                this.mTop = this.$imageW.position().top + this.gt;

                this.$D.on("mousemove", this.move)
                this.$wEle.off(this.MOUSEDOWN)
            },
            up() {
                this.$wEle.on(this.MOUSEDOWN, this.wheel);
                this.$D.off("mousemove");
            },
            move(e) {
                e.preventDefault();
                let endX = e.clientX, endY = e.clientY;
                let relativeX = endX - this.startX, relativeY = endY - this.startY;
                let newLeft = relativeX + this.mLeft, newTop = relativeY + this.mTop;

                //超出底部和右侧
                let maxWidth = this.$wEle.width() - 15;
                let maxHeight = this.$wEle.height() - 15;
                if (newLeft > maxWidth) {
                    newLeft = maxWidth
                }
                if (newTop > maxHeight) {
                    newTop = maxHeight
                }

                //超出顶部和左侧
                let currWidth = this.imageData.width - 15;
                let currHeight = this.imageData.height - 15;

                if (-newLeft > currWidth) {
                    newLeft = -currWidth
                }
                if (-newTop > currHeight) {
                    newTop = -currHeight
                }

                this.$imageW.css({
                    left: newLeft,
                    top: newTop
                });

                $.extend(this.imageData, {
                    left: newLeft,
                    top: newTop
                });
            },
            handleP(i) {
                return {
                    left: 20 + i % this.markCount * 300 + "px",
                    top: 20 + Math.floor(i / this.markCount) * 300 + "px"
                }
            }
        },
        computed: {
            markname() {
                return marknameStorage.get()
            }
        }
    }
</script>

<style lang="less" scoped>
    #dxImg {
        width: 100%;
        height: 100%;
        position: relative;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        .mask {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 9999999;
        }

        #imageW {
            position: absolute;
            z-index: 1;
            height: 90%;

            .image-w {
                position: relative;
                height: 100%;
                width: 100%;
                display: flex;
                overflow: hidden;
            }

            .cover {
                display: inline-block;
                transform: rotate(-45deg);
                color: #808080;
                opacity: .3;
                font-size: 20px;
                user-select: none;
                position: absolute;
                z-index: 999;
                white-space: nowrap;
            }
        }

        img {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }
</style>